import React, { useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { gearTypes, getGearIcon } from "../utils/gear-helpers";
import { getPrice, getRebate } from "../utils/price-helpers";

const User = ({
  userIndex,
  watch,
  register,
  remove,
  errors,
  prices,
  startDate,
  endDate,
  setValue,
}) => {
  const [gearIcon, setGearIcon] = useState(getGearIcon(""));

  const getUserName = (index) => {
    const value = watch().users[index].name;

    return value || `Användare ${index + 1}`;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPriceValue = (typeValue) => {
    if (!startDate || !endDate) {
      setValue(`users[${userIndex}].price`, null);
      return;
    }

    if (!typeValue) {
      setValue(`users[${userIndex}].price`, null);
      return;
    }

    const gearType = gearTypes.find((x) => x.value === typeValue);
    const value = getPrice(gearType.id, startDate, endDate, prices);

    setValue(`users[${userIndex}].price`, value);
  };

  useEffect(() => {
    const value = watch().users[userIndex].gearType;
    getPriceValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const onEquipmentChange = (e) => {
    setGearIcon(getGearIcon(e.target.value));
    getPriceValue(e.target.value);
  };

  return (
    <div className="flex flex-col mt-8">
      <div className="flex items-start md:items-center">
        <div className="flex items-center justify-center w-8 h-8 mr-4 text-white bg-red-800 rounded-full">
          {gearIcon}
        </div>
        <div className="flex flex-col w-full md:flex-row">
          <h3 className="flex-1 text-xl font-semibold font-display">
            {getUserName(userIndex)}
          </h3>
          {watch().users[userIndex].price && (
            <div className="flex flex-col flex-1">
              <span className="ml-1 text-sm font-bold leading-none line-through ">
                {watch().users[userIndex].price} SEK
              </span>
              <span className="text-lg leading-none">
                {(
                  watch().users[userIndex].price * getRebate(watch().startDate)
                ).toFixed()}{" "}
                SEK
              </span>
            </div>
          )}
        </div>
        <button
          type="button"
          className="ml-auto hover:text-red-800"
          onClick={() => remove(userIndex)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
      <CustomInput
        className="flex-2"
        label="Namn"
        register={register}
        name={`users[${userIndex}].name`}
        arrayName="users"
        arrayIndex={userIndex}
        arrayProperty="name"
        errors={errors}
      />
      <div className="flex items-center space-x-4">
        <CustomInput
          className="flex-1"
          label="Ålder"
          register={register}
          name={`users[${userIndex}].age`}
          arrayName="users"
          arrayIndex={userIndex}
          arrayProperty="age"
          errors={errors}
        />
        <CustomInput
          className="flex-1"
          label="Skostorlek"
          register={register}
          name={`users[${userIndex}].shoeSize`}
          arrayName="users"
          arrayIndex={userIndex}
          arrayProperty="shoeSize"
          errors={errors}
        />
      </div>
      <div className="flex items-center space-x-4">
        <CustomInput
          className="flex-1"
          label="Längd (cm)"
          register={register}
          name={`users[${userIndex}].length`}
          arrayName="users"
          arrayIndex={userIndex}
          arrayProperty="length"
          errors={errors}
        />
        <CustomInput
          className="flex-1"
          label="Vikt (kg)"
          register={register}
          name={`users[${userIndex}].weight`}
          arrayName="users"
          arrayIndex={userIndex}
          arrayProperty="weight"
          errors={errors}
        />
      </div>
      <CustomInput
          type="textarea"
          label="Övrig information"
          register={register}
          name={`users[${userIndex}].additionalInfo`}
          arrayName="users"
          arrayIndex={userIndex}
          arrayProperty="additionalInfo"
          errors={errors}
        />
      <CustomInput
        type="select"
        label="Utrustning"
        register={register}
        name={`users[${userIndex}].gearType`}
        arrayName="users"
        arrayIndex={userIndex}
        arrayProperty="gearType"
        errors={errors}
        options={gearTypes}
        valueKey="value"
        textKey="text"
        onChange={onEquipmentChange}
      />
    </div>
  );
};

export default User;
